<template>
  <v-container class="body-container">

    <loader message="Loading resignations..."
            :show="!resignationListLoaded">
      <h1>My Resignations</h1>
    </loader>

    <div v-if="resignationListLoaded">
      <h1>My Resignations ({{ resignationList.length }})</h1>

      <b-button :to="{ name: 'resignation-new' }"
                variant="secondary">
        <font-awesome-icon icon="power-off" />
        Add Resignation
      </b-button>

      <div v-if="resignationList.length === 0">
        <div style="margin-top: 20px;">
          <div class="hint-text">No resignations created yet</div>
        </div>
      </div>

      <div v-if="resignationList.length > 0"
           class="resignation-profile-list">

        <div v-for="resignation in resignationList "
             class="resignation-profile-container"
             :class="{ 'minor' : resignation.minorFlag}">

          <h2>{{ resignation.fullName }}</h2>

          <v-row>
            <v-col>

              <resignation-status-info :resignation="resignation" />

              <div v-if="resignation.userApprovedResignationDate">
                <b-link :to="{ name: 'resignation-pending', params: { resignationId: resignation.resignationId} }">
                  View details
                </b-link>
              </div>
              <div v-else>
                <b-link :to="{ name: 'resignation-edit', params: { resignationId: resignation.resignationId} }">
                  Continue Resignation
                </b-link>
                <br>
                <b-link class="text-muted"
                        @click="deleteResignation(resignation.resignationId)">
                  Delete Resignation
                </b-link>
              </div>

            </v-col>


            <v-col>

              <v-alert v-if="resignation.errorFlag"
                       border="left"
                       type="warning">
                There is a problem with your resignation

                <v-btn outlined
                       :to="{ name: 'resignation-errors', params: { resignationId: resignation.resignationId } }">
                  View Error
                </v-btn>
              </v-alert>

            </v-col>

          </v-row>


        </div>
      </div>
    </div>

  </v-container>
</template>

<script>
import { ResignationApi } from '@/api/ResignationApi';
import handleError from '@/errorHandler';
import resignationMixins from '@/mixins/resignationMixins.ts';
import ResignationStatusInfo from '@/components/ResignationStatusInfo';

export default {
  name: 'ResignationList',
  components: { ResignationStatusInfo },
  mixins: [resignationMixins],
  data: function() {
    return {
      resignationList: null,
      resignationListLoaded: false
    };
  },
  async mounted() {
    await this.getResignationListForUser();
  },
  methods: {
    async getResignationListForUser() {
      try {
        const { data } = await ResignationApi.getResignationListForUser();
        this.resignationList = data;
        this.resignationListLoaded = true;
      } catch (e) {
        console.error(e);
        handleError(e);
      }
    },
    async deleteResignation(resignationId) {

      const confirmDelete = await this.$swal({
        title: 'Delete Resignation',
        text: 'All data submitted, including files uploaded, will be deleted. This cannot be undone.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#dc3544',
        confirmButtonText: 'Delete Resignation',
        cancelButtonText: 'Don\'t delete'
      });

      if (confirmDelete.value === true) {
        try {
          await ResignationApi.deleteResignation(resignationId);

          this.$swal({
            title: 'Success',
            text: 'The resignation has been deleted',
            icon: 'success'
          });

          const idx = this._.findIndex(this.resignationList, r => r.resignationId === resignationId);
          if (idx > -1) {
            this.resignationList.splice(idx, 1);
          }

        } catch (e) {
          handleError(e);
          console.error(e);
        }
      }
    },
    hasResolvedAllErrors(resignation) {
      const hasAnyErrors = resignation.resignationErrors?.length > 0;

      const resolved = this._.sumBy(resignation.resignationErrors, e => e.userResolvedDate != null);

      return hasAnyErrors && resolved;
    }
  }
};
</script>

<style scoped
       lang="scss">

.resignation-profile-list {

  margin-top: 24px;

  /*hr {*/
  /*  border-color: #cccccc;*/
  /*  height: 1px;*/
  /*}*/

  .resignation-profile-container {

    border: solid 1px #dedede;
    padding: 20px;
    margin-bottom: 30px;

    /*h2 {
      margin: 0 0 20px;
    }*/

    .resignation-status-info{
      font-style: italic;
    }

    &.minor {
      background: #fff url('../../assets/ribbon_child.png') no-repeat right 10px top;
    }

    .error-container {
      .warning {
        font-size: 1.6rem;

        i {
          color: red;
          font-size: 1em;
        }
      }

      ul, li {
        margin: 0;
      }
    }

    .approve-completed {
      p {
        font-size: 2.0rem;

        i {
          font-size: 1.0em;
        }

        margin-bottom: 16px;
      }

      .hint-text {
        font-size: 1.6rem;
        color: #8a8a8a;
        margin-bottom: 16px;
      }

      a {

      }
    }

    .description {

      font-size: 1.4rem;

      &.hint {
        color: #9b9b9b;
        font-style: italic;
      }
    }

    a.btn.btn-link {
      margin-top: 5px;
      font-size: 1.8rem;
      text-transform: none;
      padding: 0;
    }

  }
}

</style>
